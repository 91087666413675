<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="pa-0 py-3">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >Edit feedback category</span
                >
              </v-col>
            </v-row>

            <v-row>
                <v-col class="py-0" cols="12">
                    <v-select
                        :items="myObj.id"
                        :label="$vuetify.lang.t('$vuetify.parentCategory')"
                        readonly
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.name')" 
                        :rules="generalRule" 
                        v-model="myObj.name"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.sort')" 
                        v-model="myObj.order"
                    />
                </v-col>
                <v-col class="py-0">
                    <v-text-field 
                        :label="$vuetify.lang.t('$vuetify.note')"
                        v-model="myObj.note"
                    />
                </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="blue" @click="validate">{{
          $vuetify.lang.t("$vuetify.confirm")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "QuestionCategoryCRUD",
  props: { dialog: Boolean, myObj: Object},
  data() {
    return {
      category: [],
      valid: false,
      datas: [],
      parentCatetory: [],
      parentType: [],
      generalRule: [v => !!v || "this field is required"],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate() == true ? this.$emit('handleData', this.myObj) : console.log("false");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
  }
};
</script>

<style></style>
